export const colors = {
    primaryColor: '#5500ee',
    lightPink: '#ffeeee',
    pink: 'pink',
    lightGray: '#eee',
}

export const desktop = {
    horizontalSpacing: '1rem',
    formElements: {
        separation: '1rem',
        borderWidth: '0.05rem',
        borderRadius: '0.2rem',
        fontSize: '1rem',
        focusColor: '#5500ee',
        labelSeparation: '0.5rem',
        textPadding: '0.5rem',
    }
}

export const screenWidth = {
    small: '576px',
    medium: '768px',
    large: '992px',
    extraLarge: '1200px'
}
