import styled from "styled-components";

export const RegisterWrapper = styled.div``

export const RegisterForm = styled.form``


export const RolesList = styled.ul`
    padding-inline-start: 0;
    list-style-type: none;
    margin-top: 0.5rem;
`
export const Roles = styled.li`
    display: inline-block;
    margin-right: 1rem;
`
export const GoToLogin = styled.p`
    color: blue;
    
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`