export const actionTypes = {
    SET_IS_LOADING: 'SET_IS_LOADING',
    
    SHOW_AUTH_MODAL: 'SHOW_AUTH_MODAL',
    SET_LOGIN: 'SET_LOGIN',
    SET_USER_DATA: 'SET_USER_DATA',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',

    SAVE_DESIGN_REQUIREMENTS: 'SAVE_DESIGN_REQUIREMENTS',
    SAVE_ALL_DESIGN_REQUIREMENTS: 'SAVE_ALL_DESIGN_REQUIREMENTS',
    
}