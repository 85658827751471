import styled from "styled-components";

export const DesignRequirementDetailsWrapper = styled.div`
    padding: 0 1rem;
`

export const LeftCell = styled.dt`
    width: 30%;
    font-weight: bold;
`
export const RightCell = styled.dd`

`

export const DetailsActionBtnWrapper = styled.div`
    display: flex;
    margin: 2rem 0;
`