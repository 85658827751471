export const endpoints = {
    DESIGN_DOCUMENTS: '/design/documents',
    DESIGN_SIZES: '/design/sizes',
    DESIGN_REQUIREMENT: '/design/requirement',
    DELETE_DESIGN_REQUIREMENT: '/design/requirement/delete',
    MY_DESIGN_REQUIREMENTS: '/design/requirements',
    ALL_DESIGN_REQUIREMENTS: '/design/allRequirements',
    
    AUTH_REGISTER: '/auth/register',
    AUTH_LOGIN: '/auth/login',
}